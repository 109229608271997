<template>
    <div id="My1">
        <div class="hadheight"></div>
        <Header />
        <div class="mybox-sec1 base100">
            <div class="basecont">
                <!-- banner -->
                <swiper :options="mybox_nav" class="mybox_nav" ref="mySwiper">
                    <swiper-slide v-for="(item, index) in banner" :key="index">
                        <router-link :to="item.url" class="item">
                            <div class="tit">{{item.title}}</div>
                        </router-link>
                    </swiper-slide>
                </swiper>
                <router-view></router-view>
            </div>
        </div>
        <Footer/>
    </div>
</template>

<script>
import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";

export default {
    name: "My",
    components:{
        Header,
        Footer,
        swiper,
        swiperSlide,
    },
    data(){
        return {
            //swiper
            mybox_nav: {
                // loop: true,
                slidesPerView : 'auto',
                observer:true,
                observeParents:true,
                 watchOverflow: true,
                observeSlideChildren:true,
                autoplay: {
                delay: 4000,
                stopOnLastSlide: false,
                disableOnInteraction: false,
                },
            },
            banner:[
                {
                    title: "账户信息",
                    id: 1,
                    url: "/my/mymessage"
                },
                {
                    title: "订单中心",
                    id: 1,
                    url: "/my/myorder"
                },
                {
                    title: "收货地址",
                    id: 2,
                    url: "/my/myaddress"
                },
                {
                    title: "我的收藏",
                    id: 3,
                    url: "/my/mycollection"
                },
                {
                    title: "消息通知",
                    id: 4,
                    url: "/my/mynotice"
                },
                {
                    title: "账户与安全",
                    id: 5,
                    url: "/my/mysecurity"
                }
            ]
        }
    },
    created:{
        
    },
    methods:{

    }
}
</script>
